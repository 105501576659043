// List: https://docs.google.com/spreadsheets/d/10H6CRoCg4ZltW2sHnaDItYs4S0hc4qELMrp_oB4ieqg/edit?gid=0#gid=0

const excludedPackages = [
	352, 1957, 569, 3, 562, 1771, 192, 33, 349, 1764, 35, 1993, 40, 456, 61, 224, 1862, 641, 2237, 2149, 1948, 73,
];

// countries with New signups lower than avg play rate === 0  - 24.01.24
// prettier-ignore
export const disableATVAffiliation = ['AG', 'AM', 'AZ', 'BG', 'BO', 'BW', 'CV', 'EE', 'EG', 'GH', 'HN', 'ID', 'IN', 'KY', 'LA','MD', 'MO','MU', 'MY', 'NA', 'NI', 'PA', 'PH', 'QA', 'TH', 'TT', 'UA', 'VN', 'ZA', 'ZW'];

export function excludePackages(isLoggedIn: boolean, entityId: string, country: string) {
	// don't exclude any packages when logged in
	if (isLoggedIn) return [];

	return excludedPackages.filter(packageId => {
		// exclude Tubi in US
		const isTubiInUS = packageId === 73 && country === 'US';

		return !isTubiInUS;
	});
}
